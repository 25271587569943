<template>
  <div class="reporting_infomation">
    <el-form
      :model="queryInfo"
      class="product_form"
      label-position="left"
      label-width="100px"
    >
      <el-form-item label="所在地区：">
        <cascader-area
          :country="queryInfo.institutionCountry"
          :province="queryInfo.institutionProvince"
          :city="queryInfo.institutionCity"
          @searchCountry="searchCountry"
          @searchProvince="searchProvince"
          @searchCity="searchCity"
        />
      </el-form-item>
      <el-form-item label="行业类别：">
        <dic-product-tag
          :code.sync="queryInfo.institutionTechIdName"
          query-name="institutionTechIdName"
          name="行业类别"
          tag="行业"
          @onChange="onChange"
        ></dic-product-tag>
      </el-form-item>
      <el-form-item label="机构类型：">
        <dic-port-data
          :code.sync="queryInfo.industryType"
          type-code="010"
          query-name="industryType"
          name="机构类型"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="领域类别：">
        <dic-product-tag
          :code.sync="queryInfo.institutionFieldIdName"
          query-name="institutionFieldIdName"
          name="领域类别"
          tag="领域"
          @onChange="onChange"
        ></dic-product-tag>
      </el-form-item>
      <el-form-item label="筛选条件：" class="filterInfo">
        <el-tag
          closable
          v-for="(item, index) in tags"
          :key="index + item.name"
          @close="closeTag(index, item.field)"
        >
          {{ item.name }}：{{
            queryInfo[item.field] ? queryInfo[item.field] : "不限"
          }}
          <span
            class="circle"
            v-if="item.hasOwnProperty('valLength') && item.valLength > 0"
            >+{{ item.valLength }}</span
          >
        </el-tag>
      </el-form-item>
    </el-form>
    <div class="information">
      <div class="information_col">
        <el-button @click="multiDelete">
          批量删除
          <i class="jr-iconjr-icon-delect" />
        </el-button>
        <el-input
          placeholder="请输入报告机构关键词检索"
          v-model="queryInfo.queryConditions"
          class="lager-input"
          @change="search"
        >
          <template slot="append">
            <div @click="search">搜索</div>
          </template>
        </el-input>
      </div>
      <el-table
        :data="tableData"
        stripe
        :header-cell-style="{ background: '#F5F6F7', color: '#363E4D' }"
        style="width: 100%"
        :default-sort="{ prop: 'tableData', order: 'descending' }"
        @selection-change="handleSelectionChange"
        ref="multipleTable"
        class="table ly_table"
        :cell-style="set_cell_style"
        @sort-change="sortChange"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column prop="id" align="center" label="序号" width="80">
          <template v-slot="scope">
            <span>{{
              (queryInfo.pageNum - 1) * queryInfo.pageSize + (scope.$index + 1)
            }}</span>
          </template>
        </el-table-column>
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset />
        </template>
        <!-- <el-table-column prop="institutionIntegrity" label="数据完整度" align="center" sortable="custom" width="120">
          <template v-slot="{row}">
            <div @click="numberClick(row)" style="cursor:pointer;">
              <el-progress type="circle" :percentage="row.institutionIntegrity" :stroke-width="12"></el-progress>
            </div>
          </template>
        </el-table-column> -->
        <el-table-column label="机构名称" align="center" prop="reportManager">
          <template v-slot="{ row }">
            <div @click="numberClick(row)" style="cursor:pointer;">
              <span>{{ row.reportManager }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="报告数量"
          align="center"
          prop="num"
          width="100"
          sortable="custom"
        >
          <template v-slot="{ row }">
            <el-button type="text" @click="numberClick(row)">
              {{ row.num }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          label="机构类型"
          align="center"
          prop="industryType"
        ></el-table-column>
        <el-table-column
          prop="institutionFieldId"
          label="领域类别"
          align="center"
          width="150"
        >
          <!-- <template v-slot="{ row }">
            <span>{{ row.institutionFieldId }}</span>
          </template> -->
          <template slot-scope="scope">
            <div
              v-if="
                scope.row.institutionFieldId !== null &&
                  scope.row.institutionFieldId !== ''
              "
            >
              {{ scope.row.institutionFieldId.substring(0, 20) }}
              <span v-if="scope.row.institutionFieldId.length > 20">...</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="institutionTechId"
          label="行业类别"
          align="center"
          width="150"
        >
          <!-- <template v-slot="{ row }">
            <span>{{ row.institutionTechId }}</span>
          </template> -->
          <template slot-scope="scope">
            <div
              v-if="
                scope.row.institutionTechId !== null &&
                  scope.row.institutionTechId !== ''
              "
            >
              {{ scope.row.institutionTechId.substring(0, 20) }}
              <span v-if="scope.row.institutionTechId.length > 20">...</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="所在地区"
          align="center"
          prop="institutionCity,institutionProvince,institutionCountry"
        >
          <template slot-scope="scope">
            {{
              scope.row.institutionCountry ? scope.row.institutionCountry : ""
            }}
            {{
              scope.row.institutionProvince ? scope.row.institutionProvince : ""
            }}
            {{ scope.row.institutionCity ? scope.row.institutionCity : "" }}
          </template>
        </el-table-column>
        <el-table-column
          label="成立时间"
          align="center"
          prop="institutionCreateTime"
        ></el-table-column>
        <el-table-column
          prop="adminName"
          label="创建人"
          align="center"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="changeTime"
          label="创建时间"
          align="center"
          width="120"
          :formatter="formatTime"
        ></el-table-column>
        <el-table-column label="操作" width="180px" align="center">
          <template v-slot="{ row }">
            <button-table
              content="查看报告"
              @buttonClick="numberClick(row)"
              icon="jr-iconjr-icon-eye"
            />
            <button-table
              @buttonClick="editItem(row)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              content="删除"
              @buttonClick="deleteItem(row)"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="page"
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
      <el-dialog
        title="编辑报告机构信息"
        :visible.sync="dialogVisible"
        width="60%"
        :before-close="handleClose"
        center
      >
        <div>
          <el-form
            v-model="editData"
            class="padding_form"
            ref="editDataForm"
            label-position="right"
            label-width="120px"
          >
            <el-form-item label="机构名称：" prop="institutionName">
              <el-input v-model="editData.institutionName" readonly />
            </el-form-item>
            <el-form-item label="行业类别：">
              <dic-port-details
                :code.sync="editData.institutionTechId"
                tag="行业"
                @updateCode="updateCode"
                ref="editin"
              />
            </el-form-item>
            <el-form-item label="领域类别：">
              <dic-port-details
                :code.sync="editData.institutionFieldId"
                tag="领域"
                @updateCode="updateCode"
                ref="editin1"
              />
            </el-form-item>
            <el-form-item label="机构类型：">
              <dic-port-reout
                :code.sync="editData.industryType"
                type-code="010"
              />
            </el-form-item>
            <el-form-item label="所在城市：">
              <cascader-area
                :country="editData.institutionCountry"
                :province="editData.institutionProvince"
                :city="editData.institutionCity"
                @searchCountry="searchCountryClick"
                @searchProvince="searchProvinceClick"
                @searchCity="searchCityClick"
                ref="child"
              />
            </el-form-item>
            <el-form-item label="成立时间：" prop="institutionCreateTime">
              <data-time
                :parentYear="editData.yearsTime"
                :parentMonth="editData.monthTime"
                :parentDay="editData.dayTime"
                @dateChange="tenderStartTime"
              />
            </el-form-item>
            <el-form-item label="机构单位全称：" prop="institutionFull">
              <el-input v-model="editData.institutionFull" />
            </el-form-item>
            <el-form-item label="简介：" prop="institutionIntro">
              <el-input
                v-model="editData.institutionIntro"
                type="textarea"
                :autosize="{ minRows: 4 }"
              />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="save">确 定</el-button>
          <el-button @click="cancelClick">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import CascaderArea from "@/components/CascaderArea.vue";
import ButtonTable from "../../../components/ButtonTable.vue";
import {
  getReportingInfo,
  deleteReportInfo,
  saveUpdataInfo,
  seeOne
} from "../../../api/report";
import { pagination } from "../../../mixins/pagination";
import DicProductTag from "@/components/DicTagButton.vue";
import DicPortData from "@/components/DicRadioButton.vue";
import DicPortDetails from "../com/dicPortDetails.vue";
import DicPortReout from "../com/dicPortReout.vue";
import axios from "axios";
import { URL } from "../../../../config";
import DataTime from "../../../components/DataTime.vue";
const NeweditData = Object.freeze({
  institutionName: "", //机构名称
  reportType: "", //报告类型
  institutionCountry: "", // 国家
  institutionProvince: "", // 省份
  institutionCity: "", // 城市
  institutionCreateTime: "", //成立时间
  institutionFull: "", //机构单位全称
  institutionIntro: "", //简介
  institutionTechId: "", //行业
  institutionTechIdChild: "",
  institutionFieldId: "", //领域
  institutionFieldIdChild: "", //领域
  industryType: [],
  yearsTime: null,
  monthTime: null,
  dayTime: null
});
export default {
  mixins: [pagination],
  name: "ReportingInformation",
  components: {
    CascaderArea,
    ButtonTable,
    DicProductTag,
    DicPortData,
    DicPortDetails,
    DicPortReout,
    DataTime
  },
  data() {
    return {
      editData: { ...NeweditData },
      dialogVisible: false, //弹出层状态
      queryInfo: {
        order: 1, //1 倒序   0正序
        pageSize: 10, //条数
        pageNum: 1, //页数
        columnName: "NUM",
        institutionCountry: "", // 国家
        institutionProvince: "", // 省份
        institutionCity: "", // 城市
        queryConditions: "", //搜索字段条件
        institutionTechId: [], //行业类别
        institutionTechIdName: "",
        institutionFieldId: [], // 领域类别
        institutionFieldIdName: "",
        industryType: "" //机构类型
      },
      tableData: [],
      multipleTable: [], //表格勾选的数组
      tags: [
        {
          name: "行业类别",
          field: "institutionTechIdName",
          valLength: 0
        },
        {
          name: "机构类型",
          field: "industryType"
        },
        {
          name: "领域类别",
          field: "institutionFieldIdName",
          valLength: 0
        }
      ]
    };
  },
  created() {
    this.search();
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    }
  },
  methods: {
    // 开标时间

    tenderStartTime(year, month, day) {
      this.editData.yearsTime = year;
      this.editData.monthTime = month;
      this.editData.dayTime = day;
    },
    //下载机构信息
    downloadClick(row) {
      if (row.institutionKeys) {
        axios({
          method: "POST",
          url: `${URL}/public/downZip`,
          params: { urlList: row.institutionKeys },
          responseType: "blob",
          headers: {
            // 设置请求头
            Authorization: this.Authorization
          }
        })
          .then(res => {
            let blob = new Blob([res.data], {
              type: "application/zip"
            });
            let href = window.URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
            const a = document.createElement("a"); //创建a标签
            a.style.display = "none";
            a.href = href; // 指定下载'链接
            a.download = "数据系统报告机构信息.zip"; //指定下载文件名
            a.click(); //触发下载
            URL.revokeObjectURL(a.href); //释放URL对象
            document.body.removeChild(a); //释放标签
          })
          .catch(err => {
            //this.$message.error('文件下载失败')
          });
      } else {
        this.$alert("暂无报告数据！");
      }
    },
    //报告数量点击
    numberClick(row) {
      let text = this.$router.resolve({
        path: "/researchReport/reportingNumber",
        query: {
          institutionName: row.institutionName
        }
      });
      window.open(text.href, "_blank");
      // this.$router.push({
      //   name: 'ReportingNumber',
      //   query: {
      //     institutionName: row.institutionName,
      //   },
      // })
    },
    async save() {
      //行业
      if (this.editData.yearsTime == 0) {
        this.editData.yearsTime = null;
      }
      if (this.editData.monthTime == 0) {
        this.editData.monthTime = null;
      }
      if (this.editData.dayTime == 0) {
        this.editData.dayTime = null;
      }
      if (this.editData.institutionTechIdChild) {
        this.editData.institutionTechId = this.editData.institutionTechId.concat(
          this.editData.institutionTechIdChild
        );
      }
      //领域
      if (this.editData.institutionFieldIdChild) {
        this.editData.institutionFieldId = this.editData.institutionFieldId.concat(
          this.editData.institutionFieldIdChild
        );
      }
      const res = await saveUpdataInfo(this.editData);
      if (res.code == 200) {
        this.$message.success("修改成功");
        this.cancelClick();
      } else {
        this.$message.error(res.msg);
      }
    },
    //保存
    saveItemClick() {
      this.$refs.editDataForm.validate(async valid => {
        if (valid) {
          //行业
          if (this.editData.institutionTechIdChild) {
            this.editData.institutionTechId = this.editData.institutionTechId.concat(
              this.editData.institutionTechIdChild
            );
          } else {
            this.editData.institutionTechId = [];
          }
          //领域
          if (this.editData.institutionFieldIdChild) {
            this.editData.institutionFieldId = this.editData.institutionFieldId.concat(
              this.editData.institutionFieldIdChild
            );
          } else {
            this.editData.institutionFieldId = [];
          }
          const res = await saveUpdataInfo(this.editData);
          if (res.code == 200) {
            this.$message.success("修改成功");
            this.cancelClick();
          } else {
            this.$message.error(res.msg);
          }
        } else {
          this.$message.error("请完善基本信息!");
        }
      });
    },
    //弹出层取消
    cancelClick() {
      this.$refs.editin.clear();
      this.$refs.editin1.clear();
      this.$refs.child.clear();
      this.dialogVisible = false;
      (this.editData.yearsTime = 0),
        (this.editData.monthTime = 0),
        (this.editData.dayTime = 0),
        (this.editData.institutionCountry = ""),
        (this.editData.institutionProvince = ""),
        (this.editData.institutionCity = ""),
        this.search();
    },
    updateCode(val, tag) {
      switch (tag) {
        case "行业":
          this.editData.institutionTechIdChild = val;
          break;

        case "领域":
          this.editData.institutionFieldIdChild = val;
          break;

        default:
          break;
      }
    },
    //弹出层关闭
    handleClose() {
      this.cancelClick();
    },
    formatTime(row, column) {
      const date = new Date(row[column.property]);
      return (
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      );
    },
    // 查询条件更变时el-tag跟着改变
    onChange(val, queryName, name, checkboxVal) {
      let childrenVal = [];
      if (!this.tags.some(item => item.field === queryName)) {
        if (name === "行业类别" || name === "领域类别" || name === "机构类型") {
          this.tags.push({
            name,
            field: queryName,
            valLength: 0
          });
        } else {
          this.tags.push({
            name,
            field: queryName
          });
        }
      }
      // 拿到name在tags的下标
      let result;
      result = this.tags.findIndex(el => {
        return el.name === name;
      });
      switch (name) {
        case "机构类型":
          this.queryInfo.industryType = val;
          // if (checkboxVal && checkboxVal.length && val) {
          //   this.tags[result].valLength = checkboxVal.length;
          //   childrenVal = checkboxVal;
          //   this.queryInfo.industryType =
          //     this.queryInfo.industryType.concat(childrenVal);
          // } else {
          //   this.tags[result].valLength = 0;
          // }
          break;
        case "行业类别":
          this.queryInfo.institutionTechId = [val];
          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;
            childrenVal = checkboxVal;
            this.queryInfo.institutionTechId = this.queryInfo.institutionTechId.concat(
              childrenVal
            );
          } else {
            this.tags[result].valLength = 0;
          }
          break;
        case "领域类别":
          this.queryInfo.institutionFieldId = [val];
          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;
            childrenVal = checkboxVal;
            this.queryInfo.institutionFieldId = this.queryInfo.institutionFieldId.concat(
              childrenVal
            );
          } else {
            this.tags[result].valLength = 0;
          }
          break;
        default:
          break;
      }
      this.search();
    },
    formatString(val) {
      if (!val) {
        return [];
      } else if (!val.length) {
        return val;
      } else {
        return val.split(",");
      }
    },
    //编辑
    async editItem(row) {
      if (this.$refs.editin) {
        this.$refs.editin.getDicData();
      }
      if (this.$refs.editin1) {
        this.$refs.editin1.getDicData();
      }
      if (this.$refs.child) {
        this.$refs.child.search();
      }
      const res = await seeOne({ id: row.id });
      this.editData = res.data;
      // Object.assign(this.editData, res.data)
      // Object.assign(this.editData, row);
      //行业类别转数组
      this.editData.institutionTechId = this.formatString(
        this.editData.institutionTechId
      );
      //领域类别转数组
      this.editData.institutionFieldId = this.formatString(
        this.editData.institutionFieldId
      );
      this.editData.yearsTime = this.editData.yearsTime * 1;
      this.editData.monthTime = this.editData.monthTime * 1;
      this.editData.dayTime = this.editData.dayTime * 1;
      setTimeout(() => {
        this.dialogVisible = true;
      }, 500);
    },
    //删除
    deleteItem(row) {
      this.$confirm("确认删除吗 ?")
        .then(async () => {
          const res = await deleteReportInfo([row.id]);
          if (res.code == 200) {
            this.$message.success("删除成功！");
            this.search();
          } else {
            this.$message.error("删除失败，请稍候重试");
          }
        })
        .catch(() => {});
    },
    //勾选
    handleSelectionChange(val) {
      let arr = [];
      val.forEach(item => {
        arr.push(item.id);
      });
      this.multipleTable = arr;
    },
    //批量删除
    multiDelete(row) {
      if (this.multipleTable.length < 1) {
        this.$message({
          type: "warning",
          message: "请选择需要删除的数据项"
        });
        return;
      }
      this.$confirm("确认删除吗 ?")
        .then(async () => {
          const res = await deleteReportInfo(this.multipleTable);
          if (res.code == 200) {
            this.$message.success("删除成功！");
            this.search();
          } else {
            this.$message.error("删除失败，请稍候重试");
          }
        })
        .catch(() => {});
    },
    //获取报告机构信息
    async search() {
      const res = await getReportingInfo(this.queryInfo);
      if (res.code == 200) {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.tableData.forEach(el => {
          if (el.institutionIntegrity) {
            el.institutionIntegrity = Number(
              el.institutionIntegrity.slice(0, -1)
            );
          } else {
            el.institutionIntegrity = 0;
          }
        });
      }
    },
    sortChange(column) {
      this.pageNum = 1;
      if (column.prop === "institutionIntegrity") {
        this.queryInfo.columnName = "institution_integrity";
        if (column.order === "ascending") {
          this.queryInfo.order = 0;
        } else if (column.order === "descending") {
          this.queryInfo.order = 1;
        } else {
          this.queryInfo.order = 1;
          this.queryInfo.columnName = "update_time";
        }
      } else if (column.prop === "institutionPages") {
        this.queryInfo.columnName = "institution_pages";
        if (column.order === "ascending") {
          this.queryInfo.order = 0;
        } else if (column.order === "descending") {
          this.queryInfo.order = 1;
        } else {
          this.queryInfo.order = 1;
          this.queryInfo.columnName = "update_time";
        }
      } else if (column.prop === "num") {
        this.queryInfo.columnName = "NUM";
        if (column.order === "ascending") {
          this.queryInfo.order = 0;
        } else if (column.order === "descending") {
          this.queryInfo.order = 1;
        } else {
          this.queryInfo.order = 1;
          this.queryInfo.columnName = "NUM";
        }
      }
      this.search();
    },
    //地区
    searchCountryClick(val) {
      this.editData.institutionCountry = val;
    },
    searchProvinceClick(val) {
      this.editData.institutionProvince = val;
    },
    searchCityClick(val) {
      if (val != "北京" && val != "天津" && val != "上海" && val != "重庆") {
        this.editData.institutionCity = val;
      }
    },
    //地区
    searchCountry(val) {
      this.queryInfo.institutionCountry = val;
      this.search();
    },
    searchProvince(val) {
      this.queryInfo.institutionProvince = val;
      this.search();
    },
    searchCity(val) {
      if (val != "北京" && val != "天津" && val != "上海" && val != "重庆") {
        this.queryInfo.institutionCity = val;
        this.search();
      }
    },
    set_cell_style({ row, column, rowIndex, columnIndex }) {
      if (column.label === "机构名称" || column.label === "数据完整度") {
        return "cursor:pointer";
      }
    }
  }
};
</script>

<style lang="less" scoped>
.reporting_infomation {
  width: 100%;
  overflow: hidden;

  .padding_form {
    box-sizing: border-box;
    padding: 0 50px 0 0;
  }

  /deep/ .el-input-group__append {
    background: #1e9fff;
    color: #fff;
    border: 1px solid #1e9fff;
  }
  .product_form {
    width: 100%;
    overflow: hidden;
    padding: 16px;
    box-sizing: border-box;
    background-color: #fff;
  }

  .information {
    width: 100%;
    box-sizing: border-box;
    padding: 16px 20px;
    margin-top: 16px;
    background-color: #fff;

    .information_col {
      display: flex;
      width: 100%;
      overflow: hidden;
      flex-direction: row;
      align-content: center;
      justify-content: space-between;
    }
  }
}
/deep/ .filterInfo .el-form-item__content {
  padding: 0;
}
</style>
